import { Color, ColorOptions, Hex, Rgba } from 'types/color.type';
import LWFiles from 'components/data/Files';
import { RegexHelpers } from 'helpers/regex.helpers';
import { ColorHelpers } from 'helpers/colors.helpers';
import { TemplateVersionHelpers } from './template-version.helpers';
import cloneDeep from '../utils/cloneDeep';
import { MeasurePointOptions, Shadow, ShadowStyleOptions, TextProperties } from '../types/layerProperties.type';
import { DesignerSettings, UnitOptions, View } from '../types/template.type';
import { defaultProperties } from '../config/layer-properties/default-properties';
import Layer from '../types/layer.type';
import FormatHelpers from './format.helpers';
import Src from '../types/src.type';
import Format from '../types/format.type';
import { roundToNearestDecimal } from '../utils/roundNumbers';
import TemplateDesignerStore from '../data/template-designer-store';
import { getTemplateData } from './data.helpers';
import { PERCENTAGE_ROUNDING_PRECISION } from '../constants';

class StylingHelpers {
    /**
     * Get the layer style object.
     * @param layerProperties - The layer properties object.
     * @param animations - The animations object.
     * @param layerType - The layer type.
     * @param format - On which format the layer is being rendered.
     * @returns Layer style object.
     */
    static getLayerStyle = (layerProperties: any, animations?: any, layerType: any = 'shape', format?: any): any => {
        if (!layerProperties) return {};

        const positionAnimation = !!(animations && animations.position && animations.position.length);
        const rotationAnimation = !!(animations && animations.rotation && animations.rotation.length);
        const scaleAnimation = !!(animations && animations.scale && animations.scale.length);

        const { horizontalAlign, verticalAlign, measurePoint } = layerProperties;
        const style: any = {};

        // set background color
        if (layerProperties.background && !layerProperties.backgroundImage?.src?.url) {
            const bg = layerProperties.background;
            style.backgroundColor = this.convertColor(bg);
            style.background = this.convertColor(bg);
        }

        if (!layerProperties.background && layerProperties.backgroundImage?.src?.url && typeof layerProperties.backgroundImage?.src?.url === 'string') {
            const { src, position, size, repeat } = layerProperties.backgroundImage;

            if (src && typeof src !== 'boolean' && src.url) {
                style.background = `url(${src.url})`;
                style.backgroundImageFileSize = src.size;
            }

            if (size) style.backgroundSize = size === 'fill' ? '100% 100%' : size;
            if (position) style.backgroundPosition = position;
            if (repeat) style.backgroundRepeat = repeat;
        }

        if (layerProperties.background && layerProperties.backgroundImage?.src?.url && typeof layerProperties.backgroundImage?.src?.url === 'string') {
            const { src, position, size, repeat } = layerProperties.backgroundImage;
            if (size && position && repeat && src && typeof src !== 'boolean' && src.url) {
                const backgroundStyle = `url(${src.url}) ${position}/${size} ${repeat}, ${this.convertColor(layerProperties.background)}`;
                if (size) style.backgroundSize = size === 'fill' ? '100% 100%' : size;
                if (position) style.backgroundPosition = position;
                if (repeat) style.backgroundRepeat = repeat;
                style.backgroundImage = `url(${src.url})`;
                style.backgroundColor = this.convertColor(layerProperties.background);
                style.background = backgroundStyle;
                style.backgroundImageFileSize = src.size;
            } else if (src && typeof src !== 'boolean' && src.url) {
                style.background = `url(${src.url})`;
                style.backgroundImageFileSize = src.size;
            }
        }

        if (layerProperties.media) {
            style.objectFit = layerProperties.media.size;
            style.objectPosition = layerProperties.media.position;
            if (typeof layerProperties.media.alt === 'string') {
                style.alt = layerProperties.media.alt;
            }
            if (typeof layerProperties.media.altType === 'string') {
                style.altType = layerProperties.media.altType;
            }
        } else if (layerType === 'image') {
            style.objectFit = 'cover';
            style.objectPosition = 'center center';
        }

        // Set Lottie animation.
        if (layerProperties.lottieAnimation && layerProperties.lottieAnimation.url) {
            style.lottieAnimation = layerProperties.lottieAnimation.url;
        }

        // set width
        if (layerProperties.width) {
            const widthValue = layerProperties.width.value;
            const widthUnit = layerProperties.width.unit;

            if (widthValue || widthValue === 0) {
                style.width = widthValue + widthUnit;
            } else {
                switch (layerType) {
                    case 'image':
                    case 'video':
                        style.width = 'auto';
                        break;
                    case 'container':
                        style.width = 'fit-content';
                        break;
                    default:
                        style.width = TemplateVersionHelpers.shouldSizeBeAuto() ? 'auto' : 'fit-content';
                }
            }
        }

        // set height
        if (layerProperties.height) {
            const heightValue = layerProperties.height.value;
            const heightUnit = layerProperties.height.unit;

            if (heightValue || heightValue === 0) {
                style.height = heightValue + heightUnit;
            } else {
                switch (layerType) {
                    case 'image':
                    case 'video':
                        style.height = 'auto';
                        break;
                    case 'container':
                        style.height = 'fit-content';
                        break;
                    default:
                        style.height = TemplateVersionHelpers.shouldSizeBeAuto() ? 'auto' : 'fit-content';
                }
            }
        }

        // set max width
        if (layerProperties.maxWidth) {
            if (layerProperties.maxWidth.value || layerProperties.maxWidth.value === 0) {
                style.maxWidth = layerProperties.maxWidth.value + layerProperties.maxWidth.unit;
            }
        }

        // set max height
        if (layerProperties.maxHeight) {
            if (layerProperties.maxHeight.value || layerProperties.maxHeight.value === 0) {
                style.maxHeight = layerProperties.maxHeight.value + layerProperties.maxHeight.unit;
            }
        }

        if (horizontalAlign === 'left' && !positionAnimation) {
            style.left = 0;
            style.right = 'auto';
        } else if (horizontalAlign === 'center' && !positionAnimation) {
            if (['ne', 'se'].includes(measurePoint)) {
                style.right = '50%';
                style.transform = style.transform ? `${style.transform} translateX(50%)` : 'translateX(50%)';
            } else {
                style.left = '50%';
                style.transform = style.transform ? `${style.transform} translateX(-50%)` : 'translateX(-50%)';
            }
        } else if (horizontalAlign === 'right' && !positionAnimation) {
            style.left = 'auto';
            style.right = 0;
        } else if (layerProperties.x && typeof layerProperties.x.value === 'number' && (measurePoint === 'nw' || measurePoint === 'sw')) {
            style.left = layerProperties.x.value + layerProperties.x.unit;
            style.right = 'initial';

            // Remove the translateX property to avoid unexpected behavior.
            style.transform =
                style.transform &&
                style.transform
                    .replaceAll(', ', ',')
                    .split(' ')
                    .map((item) => (item.startsWith('translateX') ? '' : item))
                    .join(' ');
        } else if (layerProperties.x && typeof layerProperties.x.value === 'number') {
            style.left = 'initial';
            style.right = layerProperties.x.value + layerProperties.x.unit;
        }

        if (verticalAlign === 'top' && !positionAnimation) {
            style.top = 0;
            style.bottom = 'auto';
        } else if (verticalAlign === 'middle' && !positionAnimation) {
            if (['sw', 'se'].includes(measurePoint)) {
                style.bottom = '50%';
                style.transform = style.transform ? `${style.transform} translateY(50%)` : 'translateY(50%)';
            } else {
                style.top = '50%';
                style.transform = style.transform ? `${style.transform} translateY(-50%)` : 'translateY(-50%)';
            }
        } else if (verticalAlign === 'bottom' && !positionAnimation) {
            style.top = 'auto';
            style.bottom = 0;
        } else if (layerProperties.y && typeof layerProperties.y.value === 'number' && (measurePoint === 'nw' || measurePoint === 'ne')) {
            style.top = layerProperties.y.value + layerProperties.y.unit;
            style.bottom = 'initial';

            // Remove the translateY property to avoid unexpected behavior.
            style.transform =
                style.transform &&
                style.transform
                    .replaceAll(', ', ',')
                    .split(' ')
                    .map((item) => (item.startsWith('translateY') ? '' : item))
                    .join(' ');
        } else if (layerProperties.y && typeof layerProperties.y.value === 'number') {
            style.top = 'initial';
            style.bottom = layerProperties.y.value + layerProperties.y.unit;
        }

        // set position (absolute || relative)
        if (layerType !== 'format') {
            /**
             * If the layer has a position property, use that.
             * Otherwise check the x, y, horizontalAlign, and verticalAlign properties and determine if the layer should be absolute or relative.
             */
            if ('position' in layerProperties) {
                style.position = layerProperties.position;
            } else {
                if (
                    layerProperties?.x?.value ||
                    layerProperties?.x?.value === 0 ||
                    layerProperties?.y?.value ||
                    layerProperties?.y?.value === 0 ||
                    layerProperties?.horizontalAlign ||
                    layerProperties?.verticalAlign
                ) {
                    style.position = 'absolute';
                } else {
                    style.position = 'relative';
                }
            }
        }

        // set rotation
        if (layerProperties.rotation && !rotationAnimation) {
            style.transform = style.transform ? `${style.transform} rotate(${layerProperties.rotation}deg)` : `rotate(${layerProperties.rotation}deg)`;
        }

        // set skew
        if (layerProperties.skew) {
            style.transform = style.transform
                ? `${style.transform} skew(${layerProperties.skew.xOffset || 0}deg, ${layerProperties.skew.yOffset || 0}deg)`
                : `skew(${layerProperties.skew.xOffset || 0}deg, ${layerProperties.skew.yOffset || 0}deg)`;
        }

        // set scale
        if (layerProperties.scale !== undefined && !scaleAnimation) {
            const scaleValue = (() => {
                if (Array.isArray(layerProperties.scale)) {
                    return layerProperties.scale[0];
                }
                return layerProperties.scale;
            })();
            style.transform = style.transform ? `${style.transform} scale(${scaleValue})` : `scale(${scaleValue})`;
        }

        // set transform origin
        if (layerProperties.transformOrigin) {
            style.transformOrigin = `${layerProperties.transformOrigin.xOffset}% ${layerProperties.transformOrigin.yOffset}%`;
        }

        // set source
        if (layerProperties.src) {
            // deprecated
            style.src = layerProperties.src.url;
        } else if (layerProperties.media && layerProperties.media.src) {
            if (layerProperties.media.src.preview && layerProperties.media.src.preview.url && layerProperties.media.src.preview.url.startsWith('https')) {
                style.previewSrc = layerProperties.media.src.preview.url;
                style.src = layerProperties.media.src.url;
            } else {
                style.src = layerProperties.media.src.url;
            }

            if (layerType === 'image') {
                style.imageFileSize = layerProperties.media.src.size;
            }

            style.isCropped = layerProperties.media.src.isCropped;
        }

        // set volume of video or audio source
        if (layerProperties.volume) {
            style.volume = layerProperties.volume;
        }

        if (layerProperties.volumeOff) {
            style.volume = 0;
        }

        // loop
        if (layerProperties.loop) {
            style.loop = layerProperties.loop;
        }

        // hide when finished
        if (layerProperties.hideWhenFinished) {
            style.hideWhenFinished = layerProperties.hideWhenFinished;
        } else {
            style.hideWhenFinished = false;
        }

        // set border
        if (layerProperties.border && layerProperties.border.borderStyle !== 'none') {
            const { borderStyle } = layerProperties.border;

            const borderWidth = layerProperties.border.borderWidth || defaultProperties.border.borderWidth;
            const borderColor = layerProperties.border.borderColor || defaultProperties.border.borderColor;

            if (borderWidth) {
                style.borderLeftWidth = borderWidth ? borderWidth.left.value + borderWidth.left.unit : '1px';
                style.borderRightWidth = borderWidth ? borderWidth.right.value + borderWidth.right.unit : '1px';
                style.borderTopWidth = borderWidth ? borderWidth.top.value + borderWidth.top.unit : '1px';
                style.borderBottomWidth = borderWidth ? borderWidth.bottom.value + borderWidth.bottom.unit : '1px';
            }

            if (borderColor) {
                const { r, g, b, a } = borderColor.rgb;
                const { type } = borderColor;

                style.borderStyle = borderStyle;

                if (type === 'transparent') {
                    style.borderColor = 'transparent';
                } else {
                    style.borderColor = `rgba(${r},${g},${b},${a})`;
                }
            }
        }

        // set border radius
        if (layerProperties.borderRadius) {
            const { topLeft, topRight, bottomRight, bottomLeft } = layerProperties.borderRadius;
            style.borderRadius = `${topLeft.value + topLeft.unit} ${topRight.value + topRight.unit} ${bottomRight.value + bottomRight.unit} ${
                bottomLeft.value + bottomLeft.unit
            }`;
        }

        // set shadow
        if (layerProperties.shadow) {
            if (layerProperties.shadow.shadowStyle !== 'none') {
                const { xOffset, yOffset, blur, spread, color, shadowStyle } = layerProperties.shadow;
                const convertedColor = this.convertColor(color);

                if (layerType === 'image' && layerProperties.shadowPlacement === 'image') {
                    style.filter = `drop-shadow(${xOffset}px ${yOffset}px ${blur}px ${convertedColor})`;
                } else {
                    style.boxShadow = `${shadowStyle !== 'initial' ? shadowStyle : ''} ${xOffset}px ${yOffset}px ${blur}px ${spread}px ${convertedColor}`; // Leave rgba as the color and at the end. It will be removed if shadow color is added to the interface setup.

                    if (layerType === 'image') {
                        style.filter = 'none';
                    }
                }
            } else {
                if (layerType === 'image') {
                    style.filter = 'none';
                } else {
                    style.boxShadow = 'none';
                }
            }
        }

        // set opacity
        if (layerProperties.opacity >= 0) style.opacity = layerProperties.opacity;

        // set padding
        if (layerProperties.padding) {
            const { left, top, right, bottom } = layerProperties.padding;

            const _top = top.value + top.unit;
            const _right = right.value + right.unit;
            const _bottom = bottom.value + bottom.unit;
            const _left = left.value + left.unit;

            style.padding = `${_top} ${_right} ${_bottom} ${_left}`;
        }

        // set blend mode
        if (layerProperties.blendMode) {
            style.mixBlendMode = layerProperties.blendMode;
        }

        // set margin
        if (layerProperties.margin) {
            const { left, top, right, bottom } = layerProperties.margin;
            style.margin = `${top.value + top.unit} ${right.value + right.unit} ${bottom.value + bottom.unit} ${left.value + left.unit}`;
        }

        style.text = layerProperties.text;

        if (layerProperties.smartLayout) {
            style.smartLayout = layerProperties.smartLayout;
        }

        if (layerProperties.canEdit) {
            style.canEdit = layerProperties.canEdit;
        }

        if (layerProperties.overflow) {
            style.overflow = layerProperties.overflow;
        }

        if (layerType === 'container') {
            style.display = 'flex';
        }

        if (layerProperties.direction) {
            style.flexDirection = layerProperties.direction;

            if (layerProperties.justify) {
                style.justifyContent = layerProperties.justify;
            }

            if (layerProperties.align) {
                style.alignItems = layerProperties.align;
            }
        }

        if (layerProperties.columnGap) {
            style.columnGap = layerProperties.columnGap.value + layerProperties.columnGap.unit;
        }

        if (layerProperties.rowGap) {
            style.rowGap = layerProperties.rowGap.value + layerProperties.rowGap.unit;
        }

        if (layerProperties.display === false) {
            style.display = 'none';
        }

        if (layerProperties.fontSize !== undefined) {
            style.fontSize = layerProperties.fontSize.value + layerProperties.fontSize.unit;
        }

        if (layerProperties.customClassname) {
            style.customClassname = layerProperties.customClassname;
        }

        this.getTextStyle(layerProperties, style, 'normal', format);

        if (layerProperties.textBackground !== undefined) {
            style.textBackground = layerProperties.textBackground;
        }

        if (layerProperties.maskImage !== undefined) {
            style.maskImage = layerProperties.maskImage;
        }

        return style;
    };

    /**
     * Get the text style object.
     * @param layerProperties - The layer properties object.
     * @param style - The style object.
     * @param textStyle - The text style.
     * @param format - On which format the layer is being rendered.
     * @returns The style object.
     */
    static getTextStyle = (layerProperties: any, style: any, textStyle: keyof TextProperties['textStyling'], format?: any): any => {
        if (!layerProperties || !layerProperties.textStyling || !layerProperties.textStyling[textStyle]) {
            return style;
        }

        const textStyling = layerProperties.textStyling[textStyle];

        if (textStyling.textBorder && textStyling.textBorder.borderStyle === 'solid' && CSS.supports('-webkit-text-stroke', '1px black')) {
            const { color, borderWidth } = textStyling.textBorder;
            const convertColor = this.convertColor(color);
            style.WebkitTextStroke = `${borderWidth.value + borderWidth.unit} ${convertColor}`;
        }

        if (textStyling.color) {
            const color = this.convertColor(textStyling.color);
            if (textStyle === 'highlighted') {
                style.background = color;
                style.textFillColor = 'transparent';
                style.WebkitTextFillColor = 'transparent';
                style.WebkitBackgroundClip = 'text';
            } else {
                style.color = color;
            }
        }

        if (textStyling.fontFamily) {
            const { fontFamily } = textStyling;
            style.fontFamily = fontFamily;
        }

        if (textStyling.fontSource) {
            const { fontSource } = textStyling;
            style.fontSource = fontSource;
        }

        if (textStyling) {
            const { fontFamily, fontVariant, fontSize, lineHeight, letterSpacing } = textStyling;

            if (fontFamily) style.fontFamily = fontFamily;
            if (fontVariant) {
                const values = fontVariant.match(/[a-zA-Z]+|[0-9]+/g);

                if (values[0] === 'italic' || values[1] === 'italic') {
                    style.fontStyle = 'italic';
                } else if (values[0] === 'bold' || values[1] === 'bold') {
                    style.fontWeight = 'bold';
                } else if (values[0] === 'regular' || values[1] === 'regular' || values[1] === undefined) {
                    style.fontStyle = 'normal';
                }

                if (!isNaN(values[0])) {
                    style.fontWeight = +values[0];
                }
            }

            if (fontSize) {
                /**
                 * If the font size is set to vw or vh, we need to calculate the font size based on the format.
                 * Otherwise the screen width or height will be used.
                 */
                if (fontSize.unit === 'vw' && format) {
                    style.fontSize = (format.width * fontSize.value) / 100 + 'px';
                } else if (fontSize.unit === 'vh' && format) {
                    style.fontSize = (format.height * fontSize.value) / 100 + 'px';
                } else {
                    // If there is no highlighted font size, set the font size to inherit so it will be picked up from the normal styling.
                    if (textStyle === 'highlighted' && layerProperties.textStyling['highlighted'].fontSize === undefined) {
                        style.fontSize = 'inherit';
                    } else {
                        style.fontSize = fontSize.value + fontSize.unit;
                    }
                }
            }

            if (lineHeight !== undefined) style.lineHeight = lineHeight;
            if (letterSpacing !== undefined) style.letterSpacing = letterSpacing + 'px';
        }

        if (textStyling.textTransform) {
            style.textTransform = textStyling.textTransform;
        }

        if (textStyling.textShadow) {
            if (textStyling.textShadow.shadowStyle === 'outside') {
                const { xOffset, yOffset, blur, color } = textStyling.textShadow;
                const convertColor = this.convertColor(color);
                if (textStyle === 'highlighted') {
                    style.filter = `drop-shadow(${xOffset}px ${yOffset}px ${blur}px ${convertColor})`;
                } else {
                    style.textShadow = `${xOffset}px ${yOffset}px ${blur}px ${convertColor}`;
                }
            } else if (textStyling.textShadow.shadowStyle === 'none') {
                style.filter = 'unset';
                style.textShadow = 'unset';
            }
        } else {
            style.filter = 'unset';
            style.textShadow = 'unset';
        }

        if (textStyling.textAlignHorizontal) {
            style.textAlign = textStyling.textAlignHorizontal;
        }

        if (textStyling.textDecoration) {
            style.textDecoration = textStyling.textDecoration;
        }

        if (textStyling.textDecorationStyle) {
            style.textDecorationStyle = textStyling.textDecorationStyle;
        }

        if (textStyling.textAlignVertical) {
            style.textAlignVertical = textStyling.textAlignVertical;
        }

        if (textStyling.textAlignVertical) {
            style.display = 'flex';
            style.alignItems = textStyling.textAlignVertical;
        }

        if (layerProperties.display === false) {
            style.display = 'none';
        }

        return style;
    };

    /**
     * Get the hover style object.
     * @param currentTransform - The current transform string.
     * @param propertiesObj - The properties object.
     * @param currentProps - The current props object.
     * @returns The hover style object.
     */
    static getHoverStyle = (currentTransform: string, propertiesObj: any = { enableProperties: {} }, currentProps: any = {}): any => {
        const style = cloneDeep(currentProps);

        if (
            currentTransform &&
            ((propertiesObj.scale && propertiesObj.scale !== 1) || propertiesObj.translateX || propertiesObj.translateY || propertiesObj.rotation)
        ) {
            style.transform = currentTransform;
        }

        const joinTransform = (prop, suffix = '', value) => {
            const arr = style.transform.split(' ');
            const i = arr.findIndex((p) => p.includes(prop));
            arr[i] = `${prop}(${value}${suffix})`;
            style.transform = arr.join(' ');
        };

        if (currentTransform) {
            if (propertiesObj.scale && propertiesObj.scale !== 1) {
                if (currentTransform.includes('scale')) {
                    joinTransform('scale', '', propertiesObj.scale);
                } else {
                    style.transform = (style.transform || '') + ` scale(${propertiesObj.scale})`;
                }
            }
            if (propertiesObj.translateX) {
                if (currentTransform.includes('translateX')) {
                    joinTransform('translateX', 'px', propertiesObj.translateX);
                } else {
                    style.transform = (style.transform || '') + ` translateX(${propertiesObj.translateX}px)`;
                }
            }
            if (propertiesObj.translateY) {
                if (currentTransform.includes('translateY')) {
                    joinTransform('translateY', 'px', propertiesObj.translateY);
                } else {
                    style.transform = (style.transform || '') + ` translateY(${propertiesObj.translateY}px)`;
                }
            }
            if (propertiesObj.rotation) {
                if (currentTransform.includes('rotate')) {
                    joinTransform('rotate', 'deg', propertiesObj.rotation);
                } else {
                    style.transform = (style.transform || '') + ` rotate(${propertiesObj.rotation}deg)`;
                }
            }
        } else {
            if (propertiesObj.scale && propertiesObj.scale !== 1) {
                style.transform = (style.transform || '') + ` scale(${propertiesObj.scale})`;
            }
            if (propertiesObj.translateX) {
                style.transform = (style.transform || '') + ` translateX(${propertiesObj.translateX}px)`;
            }
            if (propertiesObj.translateY) {
                style.transform = (style.transform || '') + ` translateY(${propertiesObj.translateY}px)`;
            }
            if (propertiesObj.rotation) {
                style.transform = (style.transform || '') + ` rotate(${propertiesObj.rotation}deg)`;
            }
        }

        if (propertiesObj?.enableProperties?.opacity && typeof propertiesObj.opacity === 'number') {
            style.opacity = propertiesObj.opacity;
        }
        if (propertiesObj?.enableProperties?.background && propertiesObj.background) {
            style.background = this.convertColor(propertiesObj.background);
        }
        if (propertiesObj?.enableProperties?.color && propertiesObj.color) {
            style.color = this.convertColor(propertiesObj.color);
        }
        if (propertiesObj?.enableProperties?.borderColor && propertiesObj.borderColor) {
            style.borderColor = this.convertColor(propertiesObj['borderColor']);
        }

        return style;
    };

    /**
     * Gets the text for the layer with all the highlighting and other styling.
     * @param text - The text to be displayed.
     * @param highlightedTextStyling - Text styling from getTextStyle function.
     * @param highlightedCharacter - The character used to highlight the text.
     * @param textBackground - The text background properties.
     * @returns The formatted text to be displayed.
     */
    static getLayerText = (
        text: TextProperties['text'],
        highlightedTextStyling: React.CSSProperties = {},
        highlightedCharacter: DesignerSettings['highlightedCharacter'] = '*',
        textBackground?: { color: string; padding: string }
    ): string => {
        const highlighted = (() => {
            switch (highlightedCharacter) {
                case '[':
                    return new RegExp(/\[([^\]]*?)\]/gm); // []
                case '(':
                    return new RegExp(/\(([^)]*?)\)/gm); // ()
                case '{':
                    return new RegExp(/\{([^}]*?)\}/gm); // {}
                case '*':
                default:
                    return new RegExp(/\*(([\n\r]|.)*?)\*/gm); // *
            }
        })();
        const bold = new RegExp(/\^(([\n\r]|.)*?)\^/gm); // ^
        const italic = new RegExp(/~(([\n\r]|.)*?)~/gm); // ~
        const underline = new RegExp(/_(([\n\r]|.)*?)_/gm); // _
        const lineThrough = new RegExp(/\|(([\n\r]|.)*?)\|/gm); // |

        let newText = text;

        const highlightedText = highlighted.test(newText);
        const boldText = bold.test(newText);
        const italicText = italic.test(newText);
        const underlineText = underline.test(newText);
        const lineThroughText = lineThrough.test(newText);

        if (highlightedText) {
            // Remove the display and align items properties from the highlighted text styling so it will be picked up from the parent.
            delete highlightedTextStyling.display;
            delete highlightedTextStyling.alignItems;
            // Set vertical align to inherit so it will be picked up from the parent.
            highlightedTextStyling.verticalAlign = 'inherit';
            const styleString = Object.entries(highlightedTextStyling)
                .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                .join(';');

            const outerSpanStylingString = (() => {
                //Styling to get text decoration working in combination with text color gradient.
                let stylingString = `display:inline; text-decoration:${highlightedTextStyling.textDecoration}; text-decoration-style:${highlightedTextStyling.textDecorationStyle}; font-size: ${highlightedTextStyling.fontSize};`;
                if (highlightedTextStyling.background?.toString().includes('gradient')) {
                    stylingString += '-webkit-text-fill-color: black;';
                } else {
                    stylingString += `-webkit-text-fill-color:${highlightedTextStyling.background};`;
                }
                if (highlightedTextStyling.textShadow) {
                    stylingString += `filter: ${highlightedTextStyling.textShadow}`;
                }

                return stylingString;
            })();

            newText = newText.replace(
                highlighted,
                `<span style="${outerSpanStylingString}; vertical-align: inherit;" class="highlighted-text__outer"><span style="${styleString}; vertical-align: inherit;" class="highlighted-text__inner">$1</span></span>`
            );
        }

        // If text background is set, add the background color and padding to the text and wrap it in a span.
        if (textBackground && (textBackground.color || textBackground.padding)) {
            let styleString = '';

            if (textBackground.padding) {
                styleString += `padding: ${textBackground.padding};`;
            }

            if (textBackground.color) {
                styleString += `background-color: ${textBackground.color};`;
            }

            styleString += '-webkit-box-decoration-break: clone; white-space: normal; vertical-align: middle;';

            newText = newText.replace(/\n/g, `</span>\n<span style="${styleString}">`);
            newText = `<span style="${styleString}">${newText}</span>`;
        }

        if (boldText) {
            newText = newText.replace(bold, '<span style="font-weight: 600;">$1</span>');
        }

        if (italicText) {
            newText = newText.replace(italic, '<span style="font-style: italic;">$1</span>');
        }

        if (underlineText) {
            newText = newText.replace(underline, '<span style="text-decoration: underline;">$1</span>');
        }

        if (lineThroughText) {
            newText = newText.replace(lineThrough, '<span style="text-decoration: line-through;">$1</span>');
        }

        return newText;
    };

    /**
     * Convert color to CSS color string.
     * @param color - Color object.
     */
    static convertColor(color: { hex: Hex; rgb: Rgba }): string;
    static convertColor(color: Color): string;
    static convertColor(color: { hex?: Hex; rgb?: Rgba } | Color): string {
        if ('type' in color && 'points' in color) {
            if (color.type === ColorOptions.Solid) {
                const { r, g, b, a } = color.points[0].color.rgb;
                return `rgba(${r},${g},${b},${a})`;
            } else if (color.type === ColorOptions.Linear) {
                const points = color.points.map((point) => {
                    const { r, g, b, a } = point.color.rgb;
                    return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
                });
                return `linear-gradient(${color.rotation || 0}deg, ${points.toString()})`;
            } else if (color.type === ColorOptions.Radial) {
                const points = color.points.map((point) => {
                    const { r, g, b, a } = point.color.rgb;
                    return `rgba(${r},${g},${b},${a}) ${point.location * 100}%`;
                });
                return `radial-gradient(${points.toString()})`;
            }

            return ColorOptions.Transparent;
        }

        if (color.rgb) return `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
        if (color.hex) return color.hex;
        return ColorOptions.Transparent;
    }

    /**
     * Get the current position of the element.
     * @param domElement - The DOM element.
     * @returns The current position of the element.
     */
    static getCurrentPosition = (
        domElement: HTMLElement,
        layerKey: Layer['key'],
        formatKey: Format['key']
    ): { x: { value: number; unit: string }; y: { value: number; unit: string } } => {
        const style = domElement.style;
        const transform = style.transform;

        // Extract the position from computed styles
        const left = style.left;
        const right = style.right;
        const top = style.top;
        const bottom = style.bottom;

        // Initialize x and y values
        let x: [string, string] | null = null;
        let y: [string, string] | null = null;

        // Determine if left or right is used for x position
        if (left !== 'initial' && left !== 'auto') {
            x = RegexHelpers.extractMatches('valueAndUnit', left) as [string, string];
        } else if (right !== 'initial' && right !== 'auto') {
            x = RegexHelpers.extractMatches('valueAndUnit', right) as [string, string];
        }

        // Determine if top or bottom is used for y position
        if (top !== 'initial' && top !== 'auto') {
            y = RegexHelpers.extractMatches('valueAndUnit', top) as [string, string];
        } else if (bottom !== 'initial' && bottom !== 'auto') {
            y = RegexHelpers.extractMatches('valueAndUnit', bottom) as [string, string];
        }

        let translateX = 0;
        let translateY = 0;

        // Check if a transform is applied
        if (transform && transform !== 'none') {
            // Parse the transform matrix (matrix(a, b, c, d, tx, ty)) or matrix3d format
            const matrix = transform.match(/matrix\((.+)\)/) || transform.match(/matrix3d\((.+)\)/);
            if (matrix) {
                const values = matrix[1].split(',').map(Number);
                // The translation values are in the last positions for 2D and 3D matrices
                translateX = values[4] || 0; // tx in matrix(a, b, c, d, tx, ty)
                translateY = values[5] || 0; // ty in matrix(a, b, c, d, tx, ty)
            } else {
                // Handle the `translate` function with both X and Y values
                const translate = transform.match(/translate\(([^)]+)\)/);
                if (translate) {
                    const translateValues = translate[1].split(',').map((v) => parseFloat(v.trim()));
                    translateX = translateValues[0] || 0;
                    translateY = translateValues[1] || 0;
                }
            }
        }

        const frameType = getTemplateData<View['frameType']>('view.frameType');
        const measurePoint = TemplateDesignerStore.getModelWithFallback<MeasurePointOptions>([
            `layerProperties.${formatKey}.${frameType}.${layerKey}.properties.measurePoint`,
            `layerProperties.general.${frameType}.${layerKey}.properties.measurePoint`
        ]);

        const finalXValue = (() => {
            switch (measurePoint) {
                case MeasurePointOptions.NW:
                case MeasurePointOptions.SW:
                    return roundToNearestDecimal(Number(x?.[0]) + translateX, PERCENTAGE_ROUNDING_PRECISION);
                case MeasurePointOptions.NE:
                    return roundToNearestDecimal(Number(x?.[0]) - translateX, PERCENTAGE_ROUNDING_PRECISION);
                case MeasurePointOptions.SE:
                    return roundToNearestDecimal(Number(x?.[0]) - translateX, PERCENTAGE_ROUNDING_PRECISION);
                default:
                    return roundToNearestDecimal(Number(x?.[0]), PERCENTAGE_ROUNDING_PRECISION);
            }
        })();

        const finalYValue = (() => {
            switch (measurePoint) {
                case MeasurePointOptions.SE:
                case MeasurePointOptions.SW:
                    return roundToNearestDecimal(Number(y?.[0]) - translateY, PERCENTAGE_ROUNDING_PRECISION);
                case MeasurePointOptions.NE:
                case MeasurePointOptions.NW:
                    return roundToNearestDecimal(Number(y?.[0]) + translateY, PERCENTAGE_ROUNDING_PRECISION);
                default:
                    return roundToNearestDecimal(Number(y?.[0]), PERCENTAGE_ROUNDING_PRECISION);
            }
        })();

        return {
            x: { value: finalXValue, unit: x?.[1] || UnitOptions.Pixels },
            y: { value: finalYValue, unit: y?.[1] || UnitOptions.Pixels }
        };
    };

    /**
     * Get the current size of the element.
     * @param domElement - The DOM element.
     * @param zoomLevel - The zoom level.
     * @returns The current size of the element.
     */
    static getCurrentSize = (
        domElement: HTMLElement,
        zoomLevel: number
    ): {
        width: {
            value: number;
            unit: string;
        };
        height: {
            value: number;
            unit: string;
        };
    } => {
        const getSize = (size: string, rectSize: number) => {
            if (size === 'fit-content' || size === 'auto') {
                return [Math.ceil(rectSize / zoomLevel), 'px'];
            }
            return RegexHelpers.extractMatches('valueAndUnit', size);
        };

        const { width, height } = domElement.style;
        const rect = domElement.getBoundingClientRect();

        const [widthValue, widthUnit] = getSize(width, rect.width);
        const [heightValue, heightUnit] = getSize(height, rect.height);

        return {
            width: { value: Number(widthValue), unit: widthUnit.toString() },
            height: { value: Number(heightValue), unit: heightUnit.toString() }
        };
    };

    /**
     * Get the current scale of the element.
     * @param domElement - The DOM element.
     * @returns The current scale of the element.
     */
    static getCurrentScale = (domElement: HTMLElement): number => {
        const { transform } = domElement.style;
        if (!transform) return 1;

        const scale = RegexHelpers.extractMatches('transformScale', transform);
        if (!scale) return 1;

        if (scale[1] === undefined) return 1;

        return Number(scale[1]);
    };

    /**
     * Get the current position of the element.
     * @param domElement - The DOM element.
     * @returns The current rotation of the element.
     */
    static getCurrentRotation = (domElement: HTMLElement): number => {
        const { transform } = domElement.style;
        if (!transform) return 0;

        const rotation = RegexHelpers.extractMatches('transformRotate', transform);
        if (!rotation) return 0;

        if (rotation[1] === undefined) return 0;

        const newRotation = rotation[1].replace('deg', '');
        return Number(newRotation);
    };

    /**
     * Get the current transform origin of the element.
     * @param domElement - The DOM element.
     * @returns The current transform origin of the element.
     */
    static getCurrentTransformOrigin = (domElement: HTMLElement): { xOffset: number; yOffset: number } => {
        const { transformOrigin } = domElement.style;
        if (!transformOrigin) return { xOffset: 50, yOffset: 50 };

        const [xOffset, yOffset] = transformOrigin.split(' ').map((item) => Number(item.replace('%', '')));
        return { xOffset, yOffset };
    };

    /**
     * Get the current skew of the element.
     * @param domElement - The DOM element.
     * @returns The current skew of the element.
     */
    static getCurrentOpacity = (domElement: HTMLElement): number => {
        const { opacity } = domElement.style;
        if (!opacity) return 1;
        return Number(opacity);
    };

    /**
     * Get the current skew of the element.
     * @param domElement - The DOM element.
     * @returns The current skew of the element.
     */
    static getCurrentColor = (domElement: HTMLElement, type: 'color' | 'backgroundColor'): { hex: Hex; rgb: Rgba } => {
        const defaultColor = { hex: '#000000', rgb: { r: 0, g: 0, b: 0, a: 1 } };

        const color = (() => {
            if (type === 'color') return domElement.style.color;
            if (type === 'backgroundColor') return domElement.style.backgroundColor;
            return domElement.style.backgroundColor;
        })();

        if (!color || color === 'transparent') return defaultColor;

        const rgbArray = RegexHelpers.extractMatches('rgbColor', color);
        if (!rgbArray) return defaultColor;

        const [r, g, b, a] = rgbArray.slice(1).map((val, idx) => (idx === 3 ? Number(val) || 1 : Number(val)));
        const hex = ColorHelpers.convertRgbToHex([r, g, b, a]);
        if (!hex) return defaultColor;

        const rgb = ColorHelpers.convertHexToRgb(hex);
        return rgb ? { hex, rgb } : defaultColor;
    };

    /**
     * Get the current border of the element.
     * @param domElement - The DOM element.
     * @returns The current border of the element.
     */
    static getCurrentShadow = (domElement: HTMLElement): Shadow => {
        const defaultShadow = {
            shadowStyle: ShadowStyleOptions.Initial,
            color: {
                rgb: { r: 0, g: 0, b: 0, a: 1 },
                hex: '#000000'
            },
            xOffset: 0,
            yOffset: 0,
            blur: 0,
            spread: 0
        };

        const boxShadow = domElement.style.boxShadow;
        if (!boxShadow) {
            return defaultShadow;
        }

        // Extracting shadow properties.
        const shadowParts = RegexHelpers.extractMatches('boxShadow', boxShadow);
        if (!shadowParts) {
            return defaultShadow;
        }

        // Extracting RGB values and shadow properties
        const rgbMatch = RegexHelpers.extractMatches('rgbColor', shadowParts[0]);
        const rgbArray = rgbMatch.slice(1).map((val, idx) => (idx === 3 ? Number(val) || 1 : Number(val)));

        const xOffset = shadowParts[1] || '0';
        const yOffset = shadowParts[2] || '0';
        const blur = shadowParts[3] || '0';
        const spread = shadowParts[4] || '0';
        const shadowStyle = (shadowParts.includes('inset') ? 'inset' : 'initial') as ShadowStyleOptions;

        const hex = ColorHelpers.convertRgbToHex(rgbArray);
        const rgb = ColorHelpers.convertHexToRgb(hex ?? '#000000');

        return {
            shadowStyle,
            color: {
                rgb: rgb ?? { r: 0, g: 0, b: 0, a: 1 },
                hex: hex ?? '#000000'
            },
            xOffset: Number(xOffset.replace('px', '')),
            yOffset: Number(yOffset.replace('px', '')),
            blur: Number(blur.replace('px', '')),
            spread: Number(spread.replace('px', ''))
        };
    };

    /**
     * Get the media size.
     * @param layerType - The layer type.
     * @param src - The source object.
     * @returns The media size.
     */
    static getMediaSize = async (layerType: Layer['type'], src: Src): Promise<{ width: number; height: number } | undefined> => {
        const mediaSize = await (async () => {
            switch (layerType) {
                case 'video': {
                    return { width: 860, height: 480 };
                }
                case 'image': {
                    if (src.assetData) {
                        return src.assetData;
                    }

                    const imageSize = await LWFiles.loadImage(src.url);
                    return imageSize;
                }
                default:
                    return;
            }
        })();

        if (!mediaSize) return;

        const smallestFormat = FormatHelpers.getSmallestFormat();

        if (mediaSize.width > smallestFormat.width) {
            // Use the same width as the largest format and proportionally calculate the height.
            const height = (smallestFormat.width / mediaSize.width) * mediaSize.height;
            return { width: smallestFormat.width, height: Math.round(height) };
        } else if (mediaSize.height > smallestFormat.height) {
            // Use the same height as the largest format and proportionally calculate the width.
            const width = (smallestFormat.height / mediaSize.height) * mediaSize.width;
            return { width: Math.round(width), height: smallestFormat.height };
        }

        return mediaSize;
    };

    /**
     * Get a property from a layer in the DOM.
     * @param format - THe format to get the property from.
     * @param layer - The layer to get the property from.
     * @param property - The property to get.
     * @returns The property value.
     */
    static getLayerDomProperty = (format: Format['key'] | undefined, layer: Layer['key'], property: string): unknown | null => {
        let selector = `.layers-container__layer.${layer}`;
        if (format) selector += `.${format}`;

        const domElement = document.querySelector<HTMLElement>(selector);
        if (!domElement) return null;

        return window.getComputedStyle(domElement).getPropertyValue(property);
    };

    /**
     * Get a property from a layer in the DOM.
     * @param format - THe format to get the property from.
     * @param layer - The layer to get the property from.
     * @param property - The property to get.
     * @returns The property value.
     */
    static getLayerClientSize = (format: Format['key'] | undefined, layer: Layer['key']): { width: number; height: number } | null => {
        let selector = `.layers-container__layer.${layer}`;
        if (format) selector += `.${format}`;

        const domElement = document.querySelector<HTMLElement>(selector);
        if (!domElement) return null;

        return {
            width: domElement.clientWidth,
            height: domElement.clientHeight
        };
    };
}

export { StylingHelpers };
